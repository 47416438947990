import { getCurrentScope, applyScopeDataToEvent } from '@sentry/core';
import { EventProcessor, Hub, Integration, Event, EventHint, Scope } from '@sentry/types';

export type SentryDetails = Exclude<Parameters<Scope['update']>[0], undefined>;

export class SentryErrorIntegration implements Integration {
   static id = 'SentryErrorIntegration';
   name = 'SentryErrorIntegration';
   setupOnce(
      _addGlobalEventProcessor: (callback: EventProcessor) => void,
      _getCurrentHub: () => Hub
   ): void {}
   processEvent?(event: Event, hint: EventHint): Event | PromiseLike<Event | null> | null {
      const exception = hint.originalException;

      if (exception instanceof SentryError) {
         const currentScope = getCurrentScope();
         const newScope = currentScope.clone();

         newScope.update(exception.sentryDetails);
         applyScopeDataToEvent(event, newScope.getScopeData());
      }

      return event;
   }

   isDefaultInstance = true;
}

export class SentryError extends Error {
   constructor(
      message: string,
      readonly sentryDetails: SentryDetails = {}
   ) {
      super(message);
   }
}
